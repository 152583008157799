import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import iPhone_15_Pro from "../../Assets/iPhone_15_Pro_.png";
import { Autoplay, Pagination } from "swiper/modules";
import ashkal_alwan_after from "../../Assets/before-after/ashkal-alwan-after.jpg";
import ashkal_alwan_before from "../../Assets/before-after/ashkal-alwan-before.jpg";
import avida_after from "../../Assets/before-after/avida-after.jpg";
import avida_before from "../../Assets/before-after/avida-before.jpg";
import hamza_after from "../../Assets/before-after/hamza-after.jpg";
import hamza_before from "../../Assets/before-after/hamza-before.jpg";
import { FlipCard } from "../../Components";
import "./style.css";

export const Slider = () => {
  const images = [
    {
      src: ashkal_alwan_before,
      backSrc: ashkal_alwan_after,
      alt: "Ashkal Alwan",
    },
    { src: avida_before, backSrc: avida_after, alt: "Avida" },
    { src: hamza_before, backSrc: hamza_after, alt: "Hamza" },
    {
      src: ashkal_alwan_before,
      backSrc: ashkal_alwan_after,
      alt: "Ashkal Alwan",
    },
    { src: avida_before, backSrc: avida_after, alt: "Avida" },
    { src: hamza_before, backSrc: hamza_after, alt: "Hamza" },
  ];

  const swiperRef = useRef(null);

  return (
    <div
      className="swiper_container"
      onMouseEnter={() => swiperRef.current.swiper.autoplay.stop()}
      onMouseLeave={() => swiperRef.current.swiper.autoplay.start()}
    >
      <img src={iPhone_15_Pro} alt="iPhone_15_Pro" className="iPhone_15_Pro" />
      <Swiper
        ref={swiperRef}
        slidesPerView={7}
        centeredSlides={true}
        spaceBetween={50}
        grabCursor={true}
        initialSlide={3}
        // modules={[Pagination, Autoplay]}
        loop={true}
        className="mySwiper"
        breakpoints={{
          320: {
            slidesPerView: 1.5,
            spaceBetween: 10,
          },
          480: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          640: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
          1280: {
            slidesPerView: 5,
            spaceBetween: 50,
          },
        }}
        // autoplay={{
        //   delay: 3000,
        //   disableOnInteraction: false,
        // }}
      >
        {images.map((image, index) => (
          <SwiperSlide key={index}>
            <FlipCard image={image} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
