import { FaFacebookF, FaInstagram, FaTiktok } from "react-icons/fa";
import footerLogo from "../../Assets/footer_logo.png";
import single_logo from "../../Assets/single_footer_logo.png";
import footerBackground from "../../Assets/footer.png";
import "./style.css";

export const Footer = () => {
  return (
    <footer id="footer" className="main-section">
      <div className="footer">
        <img
          className="footer-bg"
          src={footerBackground}
          alt="footer section"
        />
        <div className="footer-upper">
          <div className="footer-section">
            <h5 className="footer-title">Social media</h5>
            <div className="footer-icons">
              <FaInstagram className="icon" />
              <FaFacebookF className="icon" />
              <FaTiktok className="icon" />
            </div>
          </div>
          <div className="footer-section">
            <h5 className="footer-title">Related links</h5>
            <ul className="footer-links">
              <li>Services</li>
              <li>About</li>
              <li>Projects</li>
              <li>Contact us</li>
            </ul>
          </div>
          <div className="footer-section">
            <h5 className="footer-title">location</h5>
            <div className="footer-item">
              <h6>Address</h6>
              <p>Lebanon, Tripoli, Jabal Al Baddawi, ... </p>
            </div>
            <div className="footer-item">
              <h6>Phone</h6>
              <p>(+961) 81-353-109</p>
            </div>
            <div className="footer-item">
              <h6>Email</h6>
              <p>agencymarketera8@gmail.com</p>
            </div>
          </div>
        </div>
        <div className="footer-lower">
          <img src={footerLogo} alt="marketera logo white" />
          <p className="footer-copyright">
            <img src={single_logo} alt="marketera logo small white" /> © 2024
            Marketera Agency - All rights reserved
          </p>
        </div>
      </div>
    </footer>
  );
};
